import React, {useState} from 'react';



const LoadingComponent = () => {

    return (
        <div style={{
            height: '100vh',
            width: '100%',
            display: "flex",
            zIndex: "777",
            justifyContent: "center",
            alignItems: 'center'
        }}>
            <div style={{position: "relative"}}>
                <img src={'video-240p.gif'} width={60} alt="loader" loop/>

            </div>
        </div>
    )
}

export default LoadingComponent;
